export default [
  {
    small: require('../assets/images/ourOffice/small/1.jpg'),
    large: require('../assets/images/ourOffice/large/1-full.jpg'),
    officeSpace: 'Office collaboration',
  },
  {
    small: require('../assets/images/ourOffice/small/2.jpg'),
    large: require('../assets/images/ourOffice/large/2-full.jpg'),
    officeSpace: 'Conference room',
  },
  {
    small: require('../assets/images/ourOffice/small/3.jpg'),
    large: require('../assets/images/ourOffice/large/3-full.jpg'),
    officeSpace: 'Office',
  },
  {
    small: require('../assets/images/ourOffice/small/4.jpg'),
    large: require('../assets/images/ourOffice/large/4-full.jpg'),
    officeSpace: 'Open space desk',
  },
  {
    small: require('../assets/images/ourOffice/small/5.jpg'),
    large: require('../assets/images/ourOffice/large/5-full.jpg'),
    officeSpace: 'Front desk',
  }
];
