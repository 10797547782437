const data = [
  {
    image: require('../assets/images/aboutUs/about_us_image_1.png'),
    name: 'About Us Image 1',
  },
  {
    image: require('../assets/images/aboutUs/about_us_image_2.png'),
    name: 'About Us Image 2',
  },
  {
    image: require('../assets/images/aboutUs/about_us_image_3.png'),
    name: 'About Us Image 3',
  },
  {
    image: require('../assets/images/aboutUs/about_us_image_4.png'),
    name: 'About Us Image 4',
  },
  {
    image: require('../assets/images/aboutUs/about_us_image_5.png'),
    name: 'About Us Image 5',
  },
];

export default data;
