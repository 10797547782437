import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ImageZoom from 'react-medium-image-zoom';
import { graphql } from 'gatsby';
import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';
import aboutUsData from '../data/about-us-data';
import officePhotos from '../data/office-photos';
import CompaniesIcons from '../components/CompaniesIcons';
import 'lazysizes';
import CaseStudyBanner from '../components/CaseStudyBanner';
import Subtitle from '../components/Subsection/Subtitle';
import DotSubtitle from '../components/DotSubtitle';

const thumbnailPage = officePhotos[1].small;

const renderSectionSubtitle = (title, afterTitle) => (
  <Subtitle
    title={title}
    afterTitle={afterTitle}
    classNames={classnames(
      'about-us__title',
      'subsection-subtitle--align-center'
    )}
    afterTitleClassNames="about-us__after-title"
    withoutLine
    titleType="withDot"
  />
);

const renderSectionWhyWorkWithUs = () => (
  <div className="dark-content">
    <h2
      className="font-display font-weight-bold dark-content__title"
      tabIndex="0"
    >
      Why work with us?
    </h2>
    <p className="dark-content__after-title" tabIndex="0">
      We are passionate about technology and commited to excellence in
      everything we do. And yes—we love what we do!
    </p>
    <div className="dark-content__values main">
      <div className="dark-content__values-item" tabIndex="0">
        <h3>10+</h3>
        <p>Years on the market</p>
      </div>
      <div className="dark-content__values-item no-border" tabIndex="0">
        <h3>
          200
          <span>+</span>
        </h3>
        <p>Completed projects</p>
      </div>
      <div className="dark-content__values-item" tabIndex="0">
        <h3>125+</h3>
        <p>People</p>
      </div>
      <div className="dark-content__values-item" tabIndex="0">
        <h3>
          100
          <span>%</span>
        </h3>
        <p>Happy clients</p>
      </div>
    </div>
  </div>
);

const renderHelmet = () => (
  <Helmet
    title="About Us - Get To Know Us | MCRO Culture"
    description="MCRO is a leading software development company offering full-stack digital solutions
    and consultancy services for businesses worldwide."
    keywords="Cluj-Napoca Office, Strong Team, Blockchain, React, React Native,
    Angular, Ionic, iOS, Android, NodeJS, PHP, AWS, Devops, Security, QA, Automation"
    thumbnail={thumbnailPage}
    canonical
    canonicalPage="about-us"
  />
);

const renderSection = ({ subtitle, content, contentClassNames }) => (
  <div className="main__section">
    {subtitle}
    <div className={classnames('main__section__content', contentClassNames)}>
      {content}
    </div>
  </div>
);

renderSection.propTypes = {
  subtitle: PropTypes.string,
  content: PropTypes.shape({}),
  contentClassNames: PropTypes.string,
};

renderSection.defaultProps = {
  subtitle: null,
  content: null,
  contentClassNames: null,
};

const renderGetToKnowUsSection = () => {
  const getToKnowUsContent = [];

  aboutUsData.forEach((data, index) => {
    getToKnowUsContent.push(
      <div key={`about_us_image_${data.name}`} id={`about_us_image_${index}`}>
        <img
          className="content__about-us-image lazyload"
          data-src={data.image}
          alt={data.name}
          title={data.name}
        />
      </div>
    );
  });

  return renderSection({
    subtitle: renderSectionSubtitle(
      'Meet the people who make it happen',
      'We are creatives and engineers, designers and story-tellers, leaders and achievers. We are MCRO.',
      { alignCenter: true }
    ),
    content: getToKnowUsContent,
    contentClassNames: 'content about-us-content',
  });
};

const renderCompanies = (title, afterTitle = '', Icons, iconsType) => renderSection({
  subtitle: (
    <DotSubtitle
      title={title}
      titleClassNames="side-by-side"
      afterTitle={afterTitle}
      afterTitleClassNames="our-container after-title"
    />
  ),
  content: <Icons key={`${title}-icons`} iconsType={iconsType} />,
});

const getDefaultStylesForImage = (index) => {
  if (index === 0) {
    return {
      gridColumn: '1 / span 1',
      gridRow: '1 / span 3',
    };
  }

  if (index === 1) {
    return {
      gridColumn: '2 / span 2',
      gridRow: '1 / span 4',
    };
  }

  if (index === 2) {
    return {
      gridColumn: '4 / span 1',
      gridRow: '1 / span 4',
    };
  }

  if (index === 3) {
    return {
      gridColumn: '1 / span 1',
      gridRow: '4 / span 4',
    };
  }

  if (index === 4) {
    return {
      gridColumn: '2 / span 3',
      gridRow: '5 / span 3',
    };
  }

  return null;
};

const renderOurOfficeSection = () => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 500;

  return renderSection({
    subtitle: renderSectionSubtitle(
      'Step inside our headquarters',
      'Our workplace is our playground,'
        + ' which is why we’re commited to cultivating a fun and engaging environment where creativity can thrive.',
      { ourOfficeTitle: true }
    ),
    content: (
      <div>
        <iframe
          title="virtual-tour"
          src="https://my.matterport.com/show/?m=89KPv8wXQ59"
          frameBorder="0"
          allowFullScreen
          allow="xr-spatial-tracking"
        />
        <div className="content__office">
          {officePhotos.map((item, index) => (
            <ImageZoom
              tabindex={index}
              shouldHandleZoom={() => !isMobile}
              key={item.small}
              image={{
                src: item.small,
                alt: item.officeSpace,
                title: item.officeSpace,
                style: {
                  ...getDefaultStylesForImage(index),
                  objectFit: 'cover',
                },
              }}
              defaultStyles={{
                zoomImage: { objectFit: 'cover' },
              }}
            />
          ))}
        </div>
      </div>
    ),
  });
};

const aboutUs = ({ location, data: gatsbyData }) => {
  const fluidSrc = gatsbyData.file.childImageSharp.fluid;
  return (
    <Layout location={location}>
      {renderHelmet()}
      <div className="main about-us">
        <div className="main__inner">
          <CaseStudyBanner
            category="about us"
            title="We are MCRO"
            description="We are a team of passionate full-stack engineers, UX designers,
             project managers and creatives helping businesses all over the world launch their next project."
            fluid={fluidSrc}
            buttonText="Join our team"
            onButtonPress="/careers/"
            className="about-us-banner"
          />

          {renderGetToKnowUsSection()}

          {renderSectionWhyWorkWithUs()}

          {renderOurOfficeSection()}
        </div>
      </div>
      <div className="our-container-wrapper">
        <div className="main main__inner our-container">
          {renderCompanies(
            'Our clients',
            'Enterprises from all around the world trust us to develop'
              + ' and launch their digital products.',
            CompaniesIcons,
            'clientIcons'
          )}
          {renderCompanies(
            'Our partners',
            'Collaboration is key for innovation, which is why '
              + 'our business thrives on great relationships with our business partners.',
            CompaniesIcons,
            'partnerIcons'
          )}
        </div>
      </div>
    </Layout>
  );
};

aboutUs.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

aboutUs.defaultProps = {
  data: null,
};

export default aboutUs;

export const query = graphql`
  query aboutUsPage {
    file(relativePath: { eq: "aboutUs/about-us-header-image.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
